// const configs = {
//   NODE_ENV: process.env.NODE_ENV,
//   NAME_KEY: "edulance" || "App",
//   NAME_TITLE: process.env.REACT_APP_NAME_TITLE || "app",
//   API_BASE_URL: "https://api.test.edutrail.in",
//   DEFAULT_LANGUAGE: "en",
//   IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
// };

const PRODUCTION_HOST = ["admin.edutrail.in"];
const UAT_HOST = ["admin.test.edutrail.in"];

const hostname = window?.location?.hostname;
console.log("hostname", hostname);

let configs = {};

if (
  UAT_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // UAT environment
  const SOURCE = "https://api.test.edutrail.in";
  configs = {
    NODE_ENV: process.env.NODE_ENV,
    NAME_KEY: "edulance" || "App",
    NAME_TITLE: process.env.REACT_APP_NAME_TITLE || "app",
    API_BASE_URL: SOURCE,
    DEFAULT_LANGUAGE: "en",
    IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
  };
} else if (
  PRODUCTION_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // production environment
  const SOURCE = "https://api.edutrail.in";
  configs = {
    NODE_ENV: process.env.NODE_ENV,
    NAME_KEY: "edulance" || "App",
    NAME_TITLE: process.env.REACT_APP_NAME_TITLE || "app",
    API_BASE_URL: SOURCE,
    DEFAULT_LANGUAGE: "en",
    IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
  };
} else {
  configs = {
    NODE_ENV: process.env.NODE_ENV,
    NAME_KEY: "edulance" || "App",
    NAME_TITLE: process.env.REACT_APP_NAME_TITLE || "app",
    API_BASE_URL: "https://api.test.edutrail.in",
    DEFAULT_LANGUAGE: "en",
    IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
  };
}

export default configs;
