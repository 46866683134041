const Student = {
  getStudentList: {
    url: `/admin/student/list`,
    method: "GET",
  },
  getTripList: {
    url: `/admin/getTripIds`,
    method: "GET",
  },
  getparentList: {
    url: `/admin/getParentList`,
    method: "GET",
  },
  addStudent: {
    url: `/admin/student/add`,
    method: "POST",
  },
  uploadFlightTicket: {
    url: `/admin/upload-file`,
    method: "POST",
  },

  studentStatus: (id, queryparam) => ({
    url: `/admin/student/${id}?status=${queryparam?.status}`,
    method: "PUT",
  }),

  deleteStudent: (id) => ({
    url: `/admin/student/${id}`,
    method: "DELETE",
  }),
};
export default Student;
