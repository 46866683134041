import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { selectUserData } from "../../redux/auth/index.slice";
import { getCompletePathList } from "../../routes";
import { modalNotification } from "../../utils";

import authDriver from "../../utils/auth.util";
// import { AuthDriver as authDriver, modalNotification } from "../../utils";
// import { selectUserData } from "../../redux/AuthSlice/index.slice";
// import routesMap from "../../routeControl/adminRoutes";

function AppLayout({ setRedirectPath, children }) {
  const location = useLocation();
  const params = useParams();
  const userData = useSelector(selectUserData);

  function getGeneratedPath(data) {
    try {
      return generatePath(data, params);
    } catch (error) {
      return data;
    }
  }

  const activeRoute = getCompletePathList().find((e) => {
    const routePath = getGeneratedPath(e.key);
    const browserPath = location.pathname;
    return routePath === browserPath;
  });

  // const activeRoute = getCompletePathList().find(e => e.key === location.pathname);
  // const activeRoute = getCompletePathList().find(e => e.key.search(location.pathname.replace(/[0-9]/g, '')) >=0)
  const isPrivate = activeRoute?.private;
  const isValid = authDriver(activeRoute, userData, location.pathname);

  function checkValid() {
    if (!isValid) {
      let publicPath = "/";
      let privatePath = "";
      let path = "";

      if (location.pathname.search("admin") >= 0) {
        path = "admin";
      } 

      let checkData = userData?.user_type ?? path;
      if (checkData === "admin") {
        privatePath = "/admin/trip";
      }else if(location.pathname === '/privacy-policy'){
        publicPath = "/privacy-policy";
      }else if(location.pathname === '/terms-condition'){
        publicPath = "/terms-condition";
      } else {
        privatePath = "/";
        // publicPath = "/";
      }
      if (isPrivate === true) {
        /** ******* If route type is private but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to public route******** */
        // modalNotification({
        //   type: "warning",
        //   message: "Your Session is expired. Please login again.",
        // });
        console.log(publicPath);
        setRedirectPath(publicPath);
        // return false;
      } else if (isPrivate === false) {
        /** ******* If route type is public but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to private route******** */
        console.log(privatePath);
        setRedirectPath(privatePath);
      }
    } else {
      // setRedirectPath(location.pathname);
    }
  }

  useEffect(() => {
    checkValid();
  }, [location.pathname]);

  return <>{children}</>;
}

export default AppLayout;
