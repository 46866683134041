import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    globalLoader: false,
  },
  reducers: {
    setGlobalLoader: (state, action) => {
      return {
        ...state,
        globalLoader: action.payload,
      };
    },
  },
});

export const { setGlobalLoader } = commonSlice.actions;

export default commonSlice.reducer;
