import { PrivacyPolicy } from "../pages";
import routesMap from "../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.PRIVACY_POLICY.path,
      name: "PrivacyPolicy",
      key: routesMap.PRIVACY_POLICY.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <PrivacyPolicy />,
    },
  ];
}
