import PrivateLayout from "../../layout/Private/index.layout";
import privateRoutes from "./private.route";

export const userRoutes = (t) => {
  return [
    {
      element: <PrivateLayout />,
      children: [...privateRoutes(t)],
    },
  ];
};
