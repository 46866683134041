import loadable from "@loadable/component";

export const Trip = loadable(() => import("./Trip/trip"));
export const Student  = loadable(() => import("./Student/student"))
export const Login = loadable(() => import("./Auth/login"));
export const Dashboard = loadable(() => import("./Dashboard/index"));
export const Setting = loadable(() => import("./Setting/index"));
export const Parent = loadable(() => import("./Parent/parent"));
export const TripAllDetails = loadable(() => import("./Trip/tripAllDetails"));
export const Notifications = loadable(() => import("./Notifications/index"));
export const AdminOnTrip = loadable(() => import("./AdminOnTrip/index"));
export const Transaction = loadable(() => import("./Transaction/transaction"));
export const Feedback = loadable(() => import("./FeedbackData/index"));
export const Enrolment = loadable(() => import("./EnrolmentData/index"));
export const PrivacyPolicy = loadable(() => import("./Privacy-Policy/index"));
export const TermCondition = loadable(() => import("./Term&Condition/index"));

