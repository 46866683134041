
const Notification = {
  getNotification: {
    url: `/admin/notification`,
    method: "GET",
  },
  addNotification:{
    url: `/admin/notification`,
    method: "POST",
  },
};
export default Notification;
