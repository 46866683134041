import {TermCondition } from "../pages";
import routesMap from "../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.TERM_CONDITION.path,
      name: "TermCondition",
      key: routesMap.TERM_CONDITION.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <TermCondition />,
    },
  ];
}
