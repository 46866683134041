import { Settings } from "../../apiEndPoints";
import { logger } from "../../utils";
import APIrequest from "../../utils/axios";

export const getfileTypes = async (queryParams) => {
  try {
    const payload = {
      ...Settings.fileTypes,
      queryParams,
    };
    const res = await APIrequest(payload);
    return res;
  } catch (error) {
    logger(error);
    throw error;
  }
};


export const getActivefileTypes = async (queryParams) => {
  try {
    const payload = {
      url:'/admin/file-types',
      queryParams
    };
    const res = await APIrequest(payload);
    return res;
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addTypes = async (bodyData,id) => {
  try {
    const payload = {
      ...Settings.AddfileTypes,
      bodyData,
    };
    const res = await APIrequest(payload);
    return res;
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const CheckIsJourney  = async (bodyData,id) => {
  try {
    const payload = {
      ...Settings.checkJourney,
      bodyData,
    };
    const res = await APIrequest(payload);
    return res;
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const editTypes = async (bodyData,id) => {
    try {
      const payload = {
        ...Settings.EditTypes(id),
        bodyData,
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  export const getListing = async (queryParams) => {
    try {
      const payload = {
        ...Settings.getListing,
        queryParams,
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
  
  

  export const deleteTypes = async (id) => {
    try {
      const payload = {
        ...Settings.deleteTypes(id),
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };


  export const fileStatus = async (id ,bodyData) => {
  
    try {
      const payload = {
        ...Settings.StatusTypes(id),
        bodyData
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
  export const singleFileUpload = async (id) => {
  
    try {
      const payload = {
        ...Settings.singleFileUpload(id),
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
  export const deleteOneFile = async (id) => {
  
      try {
        const payload = {
          ...Settings.deleteFile(id),
        };
        const res = await APIrequest(payload);
        return res;
      } catch (error) {
        logger(error);
        throw error;
      }
    };


    export const ChangePasswaord = async (bodyData) => {
      try {
        const payload = {
          url: `/admin/change-password`,
          method: "POST",
          bodyData
        };
        const res = await APIrequest(payload);
        return res;
      } catch (error) {
        logger(error);
        throw error;
      }
    };


    