import PrivateLayout from "../../layout/Private/index.layout";
import privateRoutes from "./private.route";

export const parentRoutes = () => {
  return [
    {
      element: <PrivateLayout />,
      children: [...privateRoutes()],
    },
  ];
};
