
const Cancelation_policy = {
    getCancelationPolicy: {
      url: `/admin/cancellationPolicy`,
      method: "GET",
    },
    AddUpdatecancellationPolicy: {
      url: `/admin/cancellationPolicy`,
      method: "POST",
    },
  };
  export default Cancelation_policy;
  