import {Trip} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Trip.path,
      name: "Trip",
      key: routesMap.Trip.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Trip />,
    },
  ];
}
