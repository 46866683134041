import React from "react";
import "./footer.css";
export default function AdminFooter() {
  return (
    <footer className="footer position-fixed bottom-0 start-0 end-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">© 2024 Edulane. </div>
          <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block copyright">
              Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
              <a href="https://greychaindesign.com/" target="_blank">
                Grey Chain
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
