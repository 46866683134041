import React from "react";
import Modal from "react-bootstrap/Modal";

function ModalComponent({
  show,
  children,
  size = "",
  handleClose,
  classAdd = "",
}) {
  return (
    <Modal
      className={classAdd}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      backdrop="static"
      onHide={handleClose}
      show={show}
      dialogClassName={size}
      size = {size}
    >
      {children}
    </Modal>
  );
}

export default ModalComponent;
