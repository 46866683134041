import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ChangePasswaord } from "../../services/setting/index";
import { logger, modalNotification } from "../../utils";
import { logout } from "../../redux/auth/index.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "antd";

function ChangePassword({ setPassModal }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loader, setLoader] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .trim()
      .required("Current password field is required"),
    newPassword: Yup.string().trim().required("New password field is required"),
    confirmPassword: Yup.string()
      .trim()
      .required("Confirm password field is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const onFinish = async (values) => {
    values?.confirmPassword && delete values?.confirmPassword;
    try {
      setLoader(true);
      const data = await ChangePasswaord(values);
      if (data?.code === 200) {
        modalNotification({
          type: "success",
          message: data?.message,
        });
        setPassModal(false);
        dispatch(logout(history, true));
      }
    } catch (error) {
      logger(error);
      setPassModal(false);
    }
    setLoader(false);
    setPassModal(false);
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Change Password</h5>
        <button
          type="button"
          onClick={() => setPassModal(false)}
          className="close"
          data-dismiss="modal"
        >
          {" "}
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFinish}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Current Password{" "}
                          <span className="required text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          placeholder=" "
                          name="oldPassword"
                          formcontrolname=""
                          className="form-control"
                        />
                        <div className="invalid-feedback">
                          {" "}
                          <ErrorMessage
                            className="invalid-feedback"
                            name={"oldPassword"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          New Password{" "}
                          <span className="required text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          placeholder=" "
                          name="newPassword"
                          formcontrolname=""
                          className="form-control"
                        />
                        <div className="invalid-feedback">
                          {" "}
                          <ErrorMessage
                            className="invalid-feedback"
                            name={"newPassword"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Confirm Password{" "}
                          <span className="required text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          placeholder=" "
                          name="confirmPassword"
                          formcontrolname=""
                          className="form-control"
                        />

                        <div className="invalid-feedback">
                          {" "}
                          {formik?.errors?.confirmPassword}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="form-group d-flex">
                        <Button
                          loading={loader}
                          htmlType="submit"
                          className="btn border-0 btn-lg btn-dark"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
