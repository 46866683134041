import CryptoJS from "crypto-js";
import moment from "moment";
import { Link } from "react-router-dom";
import modalNotification from "./notification";
import configs from "../config";

export const defaultCountryCode = "+44";

const getItem = (path, label, key, icon, children) => {
  if (children) {
    return { label, key, icon, children, path };
  }
  return { label, key, icon, path };
};

export const getHeaderData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToHeader) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToHeader) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const getFooterLink = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToFooter) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToFooter) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const removeSessionStorageToken = () => {
  if (sessionStorage.getItem(`${configs.NAME_KEY}:token`)) {
    sessionStorage.setItem(`${configs.NAME_KEY}:token`, null);
  }
};

export const setSessionStorageToken = (token) => {
  sessionStorage.setItem(
    `${configs.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${configs.NAME_KEY}-token`).toString()
  );
};

export const removeLocalStorageToken = (navigate) => {
  if (localStorage.getItem(`${configs.NAME_KEY}:token`)) {
    localStorage.setItem(`${configs.NAME_KEY}:token`, null);
  }
  if (navigate) {
    navigate("/");
  }
};

export const getSessionStorageToken = () => {
  const ciphertext = sessionStorage.getItem(`${configs.NAME_KEY}:token`);
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, `${configs.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${configs.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${configs.NAME_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${configs.NAME_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${configs.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${configs.NAME_KEY}:language`);
  if (language) {
    return ["en", "hi"].includes(language)
      ? language
      : configs.DEFAULT_LANGUAGE;
  }
  return configs.DEFAULT_LANGUAGE;
};

export const getCompleteUrl = (url) => {
  return url;
};

export function decodeQueryData(data) {
  return JSON.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace("?", "")}"}`
  );
}

export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function getSortType(data) {
  return data === "ASC" ? "asc" : "desc";
}

export function dateFormatter(params, format) {
  return params
    ? moment(params)
        .tz(moment.tz.guess())
        .format(format ?? configs.DATE_FORMAT)
    : "";
}

export function dateFormatterWithFormat(param, oldFormat, newFormat) {
  return moment(param, oldFormat).format(newFormat);
}

export function filterDateFormatter(param, format) {
  return moment(param).format(format);
}
export function encoder(code) {
  return window.btoa(code);
}
export function decoder(str) {
  // return window.atob(code);
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return window.atob(str);
  } catch (err) {
    modalNotification({
      type: "error",
      message: "No treatment available",
    });
    return false;
  }
}
export function momentDateFormatter(param, format) {
  return moment(param, format);
}

export const momentTimeFormatter = (param) => {
  return moment(param);
};

export const convertToMinutes = (hours, min = 0) => {
  let minutes = hours * 60;

  return minutes + min;
};

export const getTime = (startTime, endTime, minute) => {
  let arr = [];
  for (
    let time = momentTimeFormatter(startTime);
    time <= momentTimeFormatter(endTime);
    time
      // .add(minute === 0 ? convertToMinutes(hour) : minute, "minutes")
      .add(minute, "minutes")
      .format("HH:mm")
  ) {
    arr.push(dateFormatter(time, "HH:mm"));
  }
  return arr;
};

export const getTimeByShift = (
  data,
  hours,
  minutes,
  date,
  withRange = false
) => {
  let morning = [];
  let afternoon = [];
  let night = [];
  let shiftobj = {};
  if (withRange) {
    data.forEach((item) => {
      let nextTime = momentTimeFormatter(`${date} ${item}`)
        .add(
          // convertToMinutes(duration, minute) - (duration ? minute : 0),
          convertToMinutes(hours, minutes),
          "minutes"
        )
        .format("HH:mm");
      if (item <= "11:55") {
        morning.push(`${item} - ${nextTime}`);
      } else if (item <= "16:55") {
        afternoon.push(`${item} - ${nextTime}`);
      } else {
        night.push(`${item} - ${nextTime}`);
      }
    });
  } else {
    data.forEach((item) => {
      if (item <= "11:55") {
        morning.push(item);
      } else if (item <= "16:55") {
        afternoon.push(item);
      } else {
        night.push(item);
      }
    });
  }

  shiftobj = {
    morning,
    afternoon,
    night,
  };
  return shiftobj;
};

export const getDisabledTimeSlot = (data, date, duration, minute = 0) => {
  let arr = [];
  if (data?.[date]?.length > 0) {
    data?.[date].forEach((item) => {
      let nextTime = momentTimeFormatter(`${date} ${item}`)
        .add(
          // convertToMinutes(duration, minute) - (duration ? minute : 0),
          convertToMinutes(duration, minute) - 5,
          "minutes"
        )
        .format("DD-MM-YYYY HH:mm");
      let previousTime = momentTimeFormatter(`${date} ${item}`)
        .subtract(
          // convertToMinutes(duration, minute) - (duration ? minute : 0),
          convertToMinutes(duration, minute) - 5,
          "minutes"
        )
        .format("DD-MM-YYYY HH:mm");
      for (
        let time = momentDateFormatter(`${previousTime}`, "DD-MM-YYYY HH:mm");
        time <= momentDateFormatter(`${nextTime}`, "DD-MM-YYYY HH:mm");
        time.add(5, "minutes").format("HH:mm")
      ) {
        if (data?.[date].includes(dateFormatter(time, "HH:mm")) === false) {
          arr.push(dateFormatter(time, "HH:mm"));
        }
      }
    });
  }

  let unique = [...new Set(arr)];
  return unique;
};

export function readMoreTextShow(data, showMoreText, extraReadClass) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data?.length < 50) {
    return <>{data}</>;
  }

  return (
    <p className="mb-0">
      {data.substring(0, 45)}...
      {showMoreText ? (
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            showMoreText({ data });
          }}
          className={`${extraReadClass}`}
        >
          <strong>Read More</strong>
          {/* {t("common.readMore")} */}
        </Link>
      ) : (
        ""
      )}
    </p>
  );
}

export function PhoneNumber({ countryCode, contactNumber }) {
  if (countryCode && contactNumber) {
    return <>{`${countryCode}-${contactNumber}`}</>;
  }
  return <span className="center">-</span>;
}

export function otpRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}

export function phoneRegex() {
  let regex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return regex;
}

export const setSessionStorage = (keyName, formData) => {
  let stringData = JSON.stringify(formData);
  sessionStorage.setItem(
    `${configs.NAME_KEY}:${keyName}`,
    CryptoJS.AES.encrypt(
      stringData,
      `${configs.NAME_KEY}-${keyName}`
    ).toString()
  );
};

export const getSessionStorage = (keyName) => {
  const cipherText = sessionStorage.getItem(`${configs.NAME_KEY}:${keyName}`);
  if (cipherText) {
    const bytes = CryptoJS.AES.decrypt(
      cipherText,
      `${configs.NAME_KEY}-${keyName}`
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return false;
};

export const removeSessionStorage = (keyName) => {
  if (sessionStorage.getItem(`${configs.NAME_KEY}:${keyName}`)) {
    sessionStorage.setItem(`${configs.NAME_KEY}:${keyName}`, "");
  }
};

export const getCharLeft = (char, maxChar) => {
  if (char && maxChar) return maxChar - char.length;
  else return maxChar;
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const generateTimeSlot = (
  timeSlotData,
  durationHour = 2,
  durationMinute = 30
) => {
  let dateSlot = [];
  Object.keys(timeSlotData).map((date) => {
    let timeSlot = [];
    timeSlotData[date].map((item) => {
      let toTime = momentTimeFormatter(`${date} ${item}`)
        .add(convertToMinutes(durationHour, durationMinute), "minutes")
        .format("HH:mm");
      timeSlot.push({
        from_time: item,
        to_time: toTime,
      });
    });
    dateSlot.push({
      date,
      slots: timeSlot,
    });
  });
  return dateSlot;
};

export const generateEditTimeSlot = (data) => {
  let dateData = {};
  data.map((e) => {
    dateData = { ...dateData, [e.date]: e.slots.map((z) => z.from_time) };
  });
  return dateData;
};

export const generateEditAvailableTimeSlot = (data, selectDate) => {
  let availableSlot = [];
  data.forEach((e) => {
    if (e.date === selectDate) {
      e.slots.forEach((z) => {
        if (z.availablity !== 1) {
          availableSlot.push(z.from_time);
        }
      });
    }
  });
  return availableSlot;
};

export const phoneNumberField = (e) => {
  let ASCIICode = e.which ? e.which : e.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    e.preventDefault();
  }
};

export const downloadPdf = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = "Invoice.pdf";
  link.target = "_blank";
  link.click();
};

export const getPercentage = (value, percentage) => {
  return (value / 100) * percentage;
};

export const getAddGoogleCalenderUrl = (bookingTitle, bookingDate, toTime) => {
  let timeDate = `${dateFormatter(
    bookingDate,
    "YYYYMMDDTHHmmss"
  )}Z/${dateFormatter(bookingDate, "YYYYMMDDT")}${toTime?.replace(/:/g, "")}`;
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${bookingTitle}&dates=${timeDate}`;
};

export const agoDateTime = (addHour, addType, format, date = "") => {
  let getCurrentTime = momentTimeFormatter(date || new Date());
  return getCurrentTime.add(addHour, addType).format(format);
};

export const arrayDataToString = (data) => {
  let uniqueData = [...new Set(data)];
  let newData = "";
  if (uniqueData) {
    uniqueData?.map((item, key) => {
      return uniqueData?.length !== key + 1
        ? (newData += `${item}, `)
        : (newData += `${item}.`);
    });
  }
  return data ? newData : "-";
};

export const experienceCount = (data) => {
  if (data !== undefined) {
    let checkData = data.find((d) => {
      return d.search(/[+]/gi) > 0;
    });

    if (checkData) {
      return checkData;
    }
  }
};

export const dateTimeFormatter = (date, format) => {
  return date ? moment(date).local().format(format) : "-";
};

export const checkValidData = (data) => {
  return data || "-";
};
export const checkValidCount = (data) => {
  return <>{data || 0}</>;
};

export const checkValidPrice = (data) => {
  return (
    <>
      {data
        ? `£ ${parseFloat(data).toFixed(2)}`
        : `£ ${parseFloat(0).toFixed(2)}`}
    </>
  );
};

export function ImageElement({
  previewSource = "",
  source,
  alt = "image",
  ...rest
}) {
  let pattern = /^\//g;
  return (
    <>
      {previewSource ? (
        <img loading="lazy" src={previewSource} alt={alt} {...rest} />
      ) : (
        <img
          loading="lazy"
          src={`${configs.IMAGE_URL}/${source?.replace(pattern, "")}`}
          alt={alt}
          {...rest}
        />
      )}
    </>
  );
}
export function commasFormatter(data) {
  return data.join(", ");
}
export const textFormatter = (data) => {
  return data && data?.charAt(0)?.toUpperCase() + data.slice(1);
};

export function removeSidebar() {
  let element = document.getElementById("layout-wrapper");
  element?.classList?.remove("sidebar-enable");

  let element2 = document.getElementsByClassName(
    "dropdown-menu dropdown-menu-right"
  );
  element2[0]?.classList?.remove("show");
}

export const getSocialoptions = [
  { value: "Email", label: "Email" },
  { value: "Google", label: "Google" },
  { value: "Apple", label: "Apple" },
];

export const getTypeList = [
  { value: "Journey", label: "Journey" },
  { value: "Visualization ", label: "Visualization " },
  { value: "Affirmations", label: "Affirmations" },
  { value: "Quick Visualization", label: "Quick Visualization" },
  { value: "Exercise", label: "Exercise" },
];

export const NoDataIndication = (data) => {
  return (
    <div>
      <p className="text-center text-muted">No records found.</p>
    </div>
  );
  //   <div className="spinner-border" role="status">
  //   <span className="sr-only">Loading...</span>
  // </div>
};

export const GetSomeDelay = function (fn, d) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, arguments);
    }, d);
  };
};

export const subscriptionDates = [
  {
    name: "1 Week",
    value: "subscription-1w",
  },
  {
    name: "1 Month",
    value: "subscription-1m",
  },
  {
    name: "2 Month",
    value: "subscription-2m",
  },
  {
    name: "3 Month",
    value: "subscription-3m",
  },
  {
    name: "6 Month",
    value: "subscription-6m",
  },
  {
    name: "1 yr",
    value: "subscription-12m",
  },
];

export const TextWithEllipsis = (text) => {
  const maxLength = 36;
  if (text?.length <= maxLength) {
    return text;
  }
  const truncatedText = text?.length
    ? text?.substring(0, maxLength - 3) + "..."
    : "-";
  return truncatedText;
};


export const SubFolderOption = ['Pre Activity Read Up', 'Pen Your Thoughts', 'Assignment']
