import notification from "./Notification";
import dashboard from "./Dashboard";
import SubCategory from "./subCategory";
import Setting from "./systemSetting";
import playlistIcons from "./playlistIcons";
import Trip from "./Trip";
import Student from "./Student";
import TripAllDetails from "./TripDetails";
import AdminOnTrip from "./AdminOnTrip";
import Transaction from "./Transaction";
import Feedback from "./Feedback";
import Enrolment from "./Enrolment";

const AccessControl = {
  ...Trip,
  ...Student,
  ...notification,
  ...dashboard,
  ...SubCategory,
  ...Setting,
  ...playlistIcons,
  ...TripAllDetails,
  ...AdminOnTrip,
  ...Transaction,
  ...Feedback,
  ...Enrolment
};

export default AccessControl;
