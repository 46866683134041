
const SafetyMeasures = {
    getSafetyMeasures: {
      url: `/admin/safetyMeasures`,
      method: "GET",
    },
    AddUpdateSafetyMeasures: {
      url: `/admin/safetyMeasures`,
      method: "POST",
    },
  };
  export default SafetyMeasures;
  