import {Student} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Student.path,
      name: "Student",
      key: routesMap.Student.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Student />,
    },
  ];
}
