

import { TripAllDetails } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.TripAllDetails.path,
      name: "TripAllDetails",
      key: routesMap.TripAllDetails.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <TripAllDetails />,
    },
  ];
}
