import { Dashboard } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Dashboard.path,
      name: "Dashboard",
      key: routesMap.Dashboard.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Dashboard />,
    },
  ];
}
