import { Login } from "../pages";
import routesMap from "../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.LOGIN.path,
      name: "login",
      key: routesMap.LOGIN.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <Login />,
    },
  ];
}
