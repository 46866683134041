import { getLocalStorageToken } from "./common.util";

function authDriver(route, userData, pathname) {
  try {
    let checkData = getLocalStorageToken();

    let user = "";
    if (pathname.search("admin") >= 0) {
      user = "admin";
    } else {
      user = "user";
    }

    let userCheck = userData?.user_type ?? user;

    if (userCheck === "user" && route?.commonRoute === true) {
      return true;
    } else if (route.private) {
      if (!!checkData) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!!checkData) {
        return false;
      }
      if (route.private === false) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
}

export default authDriver;
