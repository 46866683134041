import React, { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routesMap from "../../routeControl/userRouteMap";

import {
  clearSessionStorage,
  decodeQueryData,
  navigateWithParam,
} from "../../utils";
import AppLayout from "../App/index.layout";

function AuthLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const [redirectpath, setRedirectPath] = useState("");

  // let path = pathname.replace("/", "");

  useEffect(() => {
    let pathPattern = /[\/]$/g;
    if (pathPattern.test(pathname)) {
      navigate(pathname.replace(pathPattern, ""), { replace: true });
    }
    // window.scrollTo(0, 0);
    // window.paddingTop();
    // window.addBodyClass();
    // window.removeBodyClass();
    // window.authHeight();
    if (
      // pathname !== adminRouteMap.PROVIDER_PROFILE.path &&
      pathname !== routesMap.LOGIN.path
    ) {
      clearSessionStorage();
    }
  }, [pathname]);

  useEffect(() => {
    if (redirectpath) {
      if (search) {
        let newParams = decodeQueryData(search);
        navigateWithParam(newParams, navigate, redirectpath);
      } else {
        navigate(redirectpath);
      }
    }
  }, [redirectpath]);

  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
            <Outlet />
      </AppLayout>
    </>
  );
}

export default AuthLayout;
