const AccessControl = {
  LOGIN: {
    path: "/",
  },
  FORGET_PASSWORD: {
    path: "/forgot-password",
  },
 
  RESET_PASSWORD: {
    path: "/reset-password",
  },
  VERIFICATION: {
    path: "/otp",
  },
  PRIVACY_POLICY: {
    path: "/privacy-policy",
  },
  TERM_CONDITION: {
    path: "/terms-condition",
  },
  // SIGNUP_AS_PROVIDER: {
  //   path: "/provider-registration",
  // },
};

export default AccessControl;
