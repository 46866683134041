import config from "../../config";


const Dashboard = {
  get: {
    url: `/admin/dashboard`,
    method: "GET",
  },
}
export default Dashboard;
