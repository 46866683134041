import AuthLayout from "../layout/Auth/index.layout";
import authRoutes from "./auth.route";
import privacyPolicy from "./privacyPolicy.route";
import termCondition from "./termCondition.route";
import { userRoutes } from "./User";
import { adminRoutes } from "./Admin";
import MainLayout from "../layout/Main/index.layout";
import NotFound from "../pages/notFound";
import { parentRoutes } from "./Parents";

export const routes = () => {
  return [
    {
      element: <AuthLayout />,
      children: [...authRoutes(), ...privacyPolicy(), ...termCondition()],
    },
    {
      element: <MainLayout />,
      children: [...userRoutes(), ...adminRoutes(), ...parentRoutes()],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
};

export const routesList = () => {
  
  let routeArr = [
    ...authRoutes(),
    ...userRoutes()[0].children,
    ...adminRoutes()[0].children,
    ...parentRoutes()[0].children,
  ];
  
  return [...routeArr];
};

export const moduleRoutesList = () => {
  let routeArr = {
    user: [
      ...userRoutes()[0].children,
    ],
    parent: [
      ...parentRoutes()[0].children
    ],
    provider: [
      ...adminRoutes()[0].children,
      // ...adminRoutes(t)[1].children
    ],
  };
  return routeArr;
};

export const getCompletePathList = () => {
  return routesList().reduce((prev, curr) => {
    prev.push(curr);
    if (curr.children) {
      prev.push(...curr.children);
    }
    return prev;
  }, []);
};
