
import { AdminOnTrip } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.AdminOnTrip.path,
      name: "AdminOnTrip",
      key: routesMap.AdminOnTrip.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <AdminOnTrip />,
    },
  ];
}
