import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUserData } from "../../redux/auth/index.slice";
import AppLayout from "../App/index.layout";
import AdminHeader from "../../components/UiElement/AdminHeader";
import Sidebar from "../../components/UiElement/AdminSideBar/sideBar";
import AdminFooter from "../../components/UiElement/AdminFooter";

function PrivateLayout() {
  const [redirectpath, setRedirectPath] = useState("");
  const history = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const userData = useSelector(selectUserData);

  let user = pathname.includes("admin") ? "admin" : "user";

  let userRole = userData?.role ?? user;
  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [redirectpath]);

  const dispatch = useDispatch();

  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
        <div id="layout-wrapper">
          <Sidebar />
          <div className="main-content">
            <AdminHeader />
            <Outlet />
            <AdminFooter />
          </div>
        </div>
      </AppLayout>
    </>
  );
}

export default PrivateLayout;
