
const Master = {
  getAirPortCityList: {
    url: `admin/airportCitiesByCountry`,
    method: "GET",
  },
    getUserList: {
      url: `/master/users`,
      method: "GET",
    },
    getAppVersion: {
        url: `/master/app-versions`,
        method: "GET",
      },
      getvalidityPlans: {
        url: `/master/validity-plans`,
        method: "GET",
      },
      getAppVersion: {
        url: `/master/app-versions`,
        method: "GET",
      },
      getCategoriesListing: {
        url: `/master/category`,
        method: "GET",
      },
      getSubCategoriesListing:(id)=>( {
        url: `/master/sub-category/${id}`,
        method: "GET",
      }),
      getPrivacyPolicy: {
        url: `/admin/privacyPolicy`,
        method: "GET",
      },
      getTermsCondition: {
        url: `/admin/termAndCondition`,
        method: "GET",
      },
  };
  export default Master;
  