import config from "../../config";


const Auth = {
  userLogin: {
    url: `/admin/login`,
    method: "POST",
  },
  userLogout: {
    url: `/admin/logout`,
    method: "POST",
  },
};
export default Auth;
