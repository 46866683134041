import {Parent} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Parent.path,
      name: "Parent",
      key: routesMap.Parent.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Parent />,
    },
  ];
}
