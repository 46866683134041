
const PrivacyPolicy = {
    getTermAndCondition: {
      url: `/admin/privacyPolicy`,
      method: "GET",
    },
    AddUpdateTermAndCondition: {
      url: `/admin/privacyPolicy`,
      method: "POST",
    },
  };
  export default PrivacyPolicy;
  