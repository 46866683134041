import React from "react";

function NotFound() {
  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-inner text-center">
         Page not found
        </div>
      </div>
    </>
  );
}

export default NotFound;
