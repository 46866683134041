import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { combineReducers } from "redux";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { authSlice, commonSlice } from "./index";
import { logger } from "../utils";
import configs from "../config";

const RootReducer = combineReducers({
  auth: authSlice,
});

const encryptor = encryptTransform({
  secretKey: `${configs.NAME_KEY}-storage`,
  onError: (error) => {
    // Handle the error.
    logger({ error });
  },
});

const persistConfig = {
  key: configs.NAME_KEY,
  storage,
  whitelist: ["auth"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const middlewares = [
  createStateSyncMiddleware({
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
  }),
];

const defaultMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
});

const store = configureStore({
  reducer: { ...persistedReducer, common: commonSlice },
  // middleware: () =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
  middleware: () => [...defaultMiddleware, ...middlewares],
  devTools: configs.NODE_ENV !== "production",
});

initStateWithPrevTab(store);

export default store;

export const Persistor = persistStore(store);
