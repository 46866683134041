
const Aboutus = {
    getAboutus: {
      url: `/admin/about-us`,
      method: "GET",
    },
    AddUpdateAboutus: {
      url: `/admin/about-us`,
      method: "POST",
    },
  };
  export default Aboutus;
  