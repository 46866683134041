
const AdminOnTrip = {
  getAdminOnTrip: {
    url: `/admin/adminOnTrip`,
    method: "Get",
  },
  addAdminOnTrip:{
    url: `/admin/adminOnTrip`,
    method: "POST",
  },
  deleteAdminOnTrip:(id)=>({
    url: `/admin/adminOnTrip/${id}`,
    method: "DELETE",
  }),
};
export default AdminOnTrip;
