import { Link } from "react-router-dom";
import routesMap from "../../../routeControl/userRouteMap";
import { useLocation } from "react-router-dom";
import "./sideBar.css";
import {
  AlertOutlined,
  CreditCardOutlined,
  SettingOutlined,
  UserOutlined,
  CommentOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
// import logo from "../../assets/images/edulance-logo.png";
import logo from "../../../assets/images/edulance-logo.png";
import { Button } from "antd";
import { useState } from "react";

function Sidebar() {
  const { pathname } = useLocation();

  const [isActive, setIsActive] = useState(false);

  const handleButtonClick = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  return (
    <>
      <div className="logo-mobile">
        <Link to="/" className="logo logo-dark">
          <span className="logosm">
            <img loading="lazy" src={logo} alt="logo" />
          </span>
        </Link>
      </div>
      <div className={isActive ? "active" : ""}>
        <Button
          className="toggle_btn toggle-mobile"
          onClick={handleButtonClick}
        ></Button>
      </div>
      <div className={isActive ? "active" : ""}>
        <div
          className="vertical-menu text-center position-fixed top-0 bottom-0"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="sideMenu"
        >
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logosm">
                <img loading="lazy" src={logo} alt="logo" />
              </span>
            </Link>
            <button
              hidden
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              data-bs-target="#sideMenu"
              aria-label="Close"
            ></button>
          </div>
          {/* <!--- Sidemenu --> */}
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li
                className={
                  pathname.includes("/tripDetail/") ||
                  pathname.includes("/trip")
                    ? `active`
                    : ""
                }
              >
                <Link to="/trip" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <AlertOutlined />
                  </span>
                  <label>Trips</label>
                </Link>
              </li>
              <li className={pathname === "/student" ? `active` : ""}>
                <Link to="/student" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <UserOutlined />
                  </span>
                  <label>Students / Parents</label>
                </Link>
              </li>

              <li className={pathname === "/admin-on-trip" ? `active` : ""}>
                <Link to="/admin-on-trip" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <CreditCardOutlined />
                  </span>
                  <label>Admin On Trip</label>
                </Link>
              </li>
              <li className={pathname === "/notifications" ? `active` : ""}>
                <Link to="/notifications" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <CreditCardOutlined />
                  </span>
                  <label>Notifications</label>
                </Link>
              </li>
              <li className={pathname === "/transaction" ? `active` : ""}>
                <Link to="/transaction" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <CreditCardOutlined />
                  </span>
                  <label>Transactions</label>
                </Link>
              </li>
              <li className={pathname === "/feedback" ? `active` : ""}>
                <Link to="/feedback" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <CommentOutlined />
                  </span>
                  <label>Feedbacks</label>
                </Link>
              </li>
              <li className={pathname === "/enrolment" ? `active` : ""}>
                <Link to="/enrolment" className="waves-effect">
                  <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-user-line"></i> */}
                    <IdcardOutlined />
                  </span>
                  <label>Enrollment Data </label>
                </Link>
              </li>
              <li className={pathname === "/setting" ? `active` : ""}>
                <Link to="/setting" className="waves-effect">
                  <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                    {/* <i className="ri-settings-2-line"></i> */}
                    <SettingOutlined />
                  </span>
                  <label>System Settings</label>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
